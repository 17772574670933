<template>
	<AddEditForm :headerTitle="$t('pages.warehouse.editWarehouse')" :onGoBack="onGoBack" :showDelete="false">
		<template v-slot:headerAddEdit>
			<button type="button" name="add-editWarehouse" class="btn btn-primary" @click="onSave">
				{{ $t('common.save') }}
			</button>
		</template>
		<template #content>
			<Accordion :headerTitle="$t('common.generalInfo')" v-if="editingWareHouse != null">
				<template v-slot:body>
					<CForm>						
						<CInput horizontal v-if="productDetail != null" :value="productDetail.name" disabled="disabled">
							<template #label>
								<div class="col-sm-3 col-form-label-">
									{{ $t('pages.product.productName') }}
									<small class="requiredInput">(*)</small>
								</div>
							</template>
						</CInput>

						<CInput horizontal :value="productPackagingSpecification" readonly>
							<template #label>
								<div class="col-sm-3 col-form-label-">
									{{ $t('pages.product.packagingSpecification') }}
									<small class="requiredInput">(*)</small>
								</div>
							</template>
						</CInput>
						
						<CurrencyInput v-model="editingWareHouse.price" :label="$t('common.price')">
							<template v-slot:headerRight>
								<small class="requiredInput">(*)</small>
							</template>
						</CurrencyInput>

						<CInput :placeholder="$t('common.enterQuantity')" v-model="editingWareHouse.quantity"
								horizontal @keypress="$func.onlyNumbers">
							<template #label>
								<div class="col-sm-3 col-form-label-">
									{{ $t('common.quantity') }}
									<small class="requiredInput">(*)</small>
								</div>
							</template>
						</CInput>						

						<CInput v-model="editingWareHouse.shipmentCode" horizontal>
							<template #label>
								<div class="col-form-label col-sm-3 col-form-label">
									{{ $t('pages.warehouse.shipmentCode') }}
									<small class="requiredInput">(*)</small>
								</div>
							</template>
						</CInput>

						<div role="group" class="form-group form-row">
							<label class="col-form-label col-sm-3 col-form-label">{{ $t('pages.warehouse.dateOfManufacture') }}</label>
							<div class="col-sm-2 input-group">
								<date-picker v-model="editingWareHouse.dateOfManufacture" format="DD/MM/YYYY"></date-picker>
							</div>
						</div>

						<div role="group" class="form-group form-row">
							<label class="col-form-label col-sm-3 col-form-label">{{ $t('pages.warehouse.expirationDate') }}</label>
							<div class="col-sm-2 input-group">
								<date-picker v-model="editingWareHouse.expirationDate" format="DD/MM/YYYY"></date-picker>
							</div>
						</div>
					</CForm>
				</template>
			</Accordion>
		</template>
	</AddEditForm>
</template>

<script>    
    import { mapGetters, mapActions } from 'vuex'
	import i18n from '@/plugins/i18n'
	import CurrencyInput from '@/components/CurrencyInput.vue'
    import Accordion from '@/components/Accordion.vue'
    import AddEditForm from '@/components/AddEditForm.vue'

	export default {
        name: 'WareHouseEdit',
		data() {
			return {
				horizontal: { label: 'col-3', input: 'col-9' },				
			}
		},
        components: {
            CurrencyInput,
            Accordion,
            AddEditForm
        },
		computed: {
            ...mapGetters('warehouse', ['editingWareHouse', 'productDetail', 'productPackagingSpecification']),
		},
		methods: {			

            onSave() {
                this.onUpdateWarehouse();
            },
            onGoBack() {
                this.$router.push('/warehouses/list');
            },
            async onUpdateWarehouse() {

				try {					
	                  
                    if (this.editingWareHouse.price <= 0) {
                        this.$store.dispatch('alert/error', i18n.t('pages.product.enterPrice'), { root: true });
                        return;
                    }
                    if (this.editingWareHouse.quantity === 0) {
                        this.$store.dispatch('alert/error', i18n.t('pages.warehouse.enterQuantity'), { root: true });
                        return;
                    }
                    

					if (this.editingWareHouse.shipmentCode === '') {
                        this.$store.dispatch('alert/error', i18n.t('pages.warehouse.enterShipmentCode'), { root: true });                      
                        return;
                    }
                    
                    var retVal = await this.$store.dispatch("warehouse/saveWareHouse");
                    if (retVal.data == true) {
                        this.$router.push('/warehouses/list');
					}
                    else {
                        var mess = i18n.t('common.unsuccessfull');
                        if (retVal.errorMessage != '') {
                            switch (retVal.errorMessage) {
                                case 'Invalid RetailUnit': mess = i18n.t('common.invalidRetailUnit'); break;
                                case 'Invalid UnitPacking': mess = i18n.t('common.invalidUnitPacking'); break;
                            }
						}

                        this.$store.dispatch('alert/error', mess);
                    }
				} catch (e) {
                    this.$store.dispatch('toast/displayNotification', { text: 'Error' });
                }
			},			        
		},
		mounted() {
            this.$store.dispatch("warehouse/editWareHouse", this.$route.params.id);			  
		},       
	}
</script>

